import React, { useState, useEffect, useRef } from 'react';
import '../styles/Hero3.css';
import { Link } from 'react-scroll';
import Footer from './Footer';

const Hero3_3 = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <section className="hero3_3" id='section3_3' aria-labelledby="hero3_3-description" >
                <p id="hero3_3-description" hidden>
                    Das Bild zeigt eine DOCH! allrounder-Verpackung,
                    die zentral auf einem Tisch platziert ist.
                    Rundherum sind frische Zutaten arrangiert,
                    darunter Karotten, Pastinaken, Lauch, Petersilie,
                    Knoblauch und Zwiebeln. Der dunkle Hintergrund betont
                    die leuchtenden Farben der Zutaten und das Produkt.
                    Die Szene vermittelt Frische, Natürlichkeit und Regionalität.
                </p>
                <div className="hero3_3-content">
                    <div className='firstBlock'>
                        <div className='buyText'>
                            <div>
                                <h1>Online</h1>
                                <p>kaufen</p>
                            </div>

                            <p className='orangeP'>
                                Hol dir den Geschmack des Marchfelds direkt nach Hause! Bestelle den DOCH! allrounder bequem online – schnell, unkompliziert und direkt zu dir geliefert. Egal ob für deine Alltagsküche oder als Geschenk für Feinschmecker: Mit nur wenigen Klicks steht dir die volle Würzpower zur Verfügung.
                            </p>

                            <div className='buyLinkContainer' ref={dropdownRef}>
                                <button
                                    className={`buyLink ${isDropdownOpen ? 'open' : ''}`}
                                    onClick={toggleDropdown}
                                    aria-haspopup="true"
                                    aria-expanded={isDropdownOpen}
                                >
                                    <span className="arrow">▼</span>
                                </button>
                                {isDropdownOpen && (
                                    <ul className='dropdown-menu' role="menu">
                                        <li role="none">
                                            <a href="https://www.amazon.de/s?k=Doch%21+Flavours+Allrounder+W%C3%BCrzmittel&__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3BL7S80IHQ1WZ&sprefix=doch+flavours+allrounder+w%C3%BCrzmittel%2Caps%2C109&ref=nb_sb_noss_2" target="_blank" rel="noopener noreferrer" role="menuitem">
                                                Amazon.de
                                            </a>
                                        </li>
                                        <li role="none">
                                            <a href="https://www.shoepping.at/search?q=Doch!%20flavours%20Allrounder%20W%C3%BCrzmittel" target="_blank" rel="noopener noreferrer" role="menuitem">
                                                Shoepping.at
                                            </a>
                                        </li>
                                        <li role="none">
                                            <a href="https://www.neckermann.at/search?filter%5Bf_Produzent%5D=141453&keyword=Doch!%20Flavours%20Allrounder%20W%C3%BCrzmittel" target="_blank" rel="noopener noreferrer" role="menuitem">
                                                Neckermann.at
                                            </a>
                                        </li>
                                        <li role="none">
                                            <a href="https://myproduct.at/brand/doch-flavours" target="_blank" rel="noopener noreferrer" role="menuitem">
                                                myproduct.at
                                            </a>
                                        </li>
                                        <li role="none">
                                            <a href="https://myproduct.de/brand/doch-flavours" target="_blank" rel="noopener noreferrer" role="menuitem">
                                                myproduct.de
                                            </a>
                                        </li>
                                        <li role="none">
                                            <a href="https://www.austriansupermarket.com/brand/doch-flavours" target="_blank" rel="noopener noreferrer" role="menuitem">
                                                AustrianSupermarket.com
                                            </a>
                                        </li>
                                        <li role="none">
                                            <a href="https://www.kaufland.de/s/?search_value=Doch%21%20Flavours" target="_blank" rel="noopener noreferrer" role="menuitem">
                                                Kaufland.de
                                            </a>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='secondBlock'>
                        <div className='buyText handel'>
                            <div>
                                <h1 className='buySecondTitle'>Im Handel</h1>
                                <p>kaufen</p>
                            </div>

                            <p className='orangeP secondText'>
                                Möchtest du DOCH! vor Ort entdecken? Besuche unsere Partnergeschäfte und nimm den DOCH! allrounder gleich mit. Lass dich inspirieren und erlebe unser Produkt hautnah – für noch mehr Geschmack und Genuss in deiner Küche.
                            </p>

                            <div className='secondBlockText'>
                                <p className='buyAddress'>
                                    <b>`s Fachl Wien</b><br />
                                    Alser Straße 43<br />
                                    1080 Wien
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

};

export default Hero3_3;
