// src/components/Hero2_2.js
import React, { useState, useEffect } from 'react';
import '../styles/Hero2.css';
import bg3 from '../assets/bg3-min.png';
import { Link } from 'react-scroll';

const Hero2_2 = () => {
    const [showDownButton, setShowDownButton] = useState(true);

    useEffect(() => {
        const orangeElement = document.getElementById('orange');

        if (!orangeElement) return;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setShowDownButton(false);
                    } else {
                        setShowDownButton(true);
                    }
                });
            },
            { threshold: 0.5 } // Настройте порог по необходимости
        );

        observer.observe(orangeElement);

        return () => {
            if (orangeElement) {
                observer.unobserve(orangeElement);
            }
        };
    }, []);

    return (
        <section className="hero2_2" id='section2_2'>
            <div className='overflow'>
                <div className='contentWrapper'>
                    {/* {showDownButton && ( */}
                    <div className='content'>
                        <h1 style={{ lineHeight: '1.1' }}>
                            <b style={{ fontWeight: '800' }}>DOCH! </b>
                            <b style={{ fontWeight: '300' }}>
                                – das sind wir,<br />
                                Christoph und Dominik
                            </b>
                        </h1>
                        <p>
                            Wir teilen die Leidenschaft für gutes,<br />
                            ehrliches Essen und eine tiefe Wertschätzung<br />
                            {/* <span id='photo' style={{ display: 'block', height: '1px' }}></span> */}
                            für nachhaltige Landwirtschaft.
                        </p>
                    </div>
                    {/* )} */}


                </div>

                <img className='bg3Img' src={bg3} alt="Background" aria-labelledby="bg3-description" />
                <p id="bg3-description" hidden>
                    Christoph und Dominik Salomon, die Gründer von DOCH!, 
                    sitzen nebeneinander an einem Tisch und lächeln direkt in die Kamera. 
                    Vor ihnen liegt eine Auswahl an frischem Gemüse, darunter Karotten, 
                    Pastinaken, Lauch, Zwiebeln und Petersilie, zusammen mit 
                    einer Verpackung des DOCH! allrounders. Der Hintergrund ist neutral weiß, 
                    wodurch die Personen und das Produkt im Mittelpunkt stehen.
                </p>
                <div className='orange' id='orange'>
                    <div className='orangeContent'>
                        <div className='left'>
                            <h1 className='h1First'>„</h1>
                            <div className='orangeWrap'>
                                <p>
                                    Ich, Christoph, komme aus einer Familie traditioneller Gemüsehändler und bringe als gelernter
                                    Koch das Wissen und die Leidenschaft für echte Geschmacksentwicklung mit.

                                </p>
                                <p>— Christoph Salomon</p>
                            </div>

                            {/* <h1 className='h1Second'>„</h1> */}
                        </div>
                        <div className='right'>
                            <h1 className='h1First'>„</h1>
                            <div className='orangeWrap'>
                                <p>
                                    Ich, Dominik, bin begeisterter Hobbykoch. Früher war meinem Mann
                                    mein Essen oft zu scharf, doch dank unserer ausgewogenen Würzmischung darf ich jetzt wieder
                                    häufiger kochen – ganz ohne Überwürzen!

                                </p>
                                <p>— Dominik Salomon</p>
                            </div>


                            {/* <h1 className='h1Second'>„</h1> */}
                        </div>
                    </div>
                </div>

                {/* Кнопка вниз (DownButton) */}
                {/* {showDownButton && (
                    <>
                        <div className="down-button btn3D">
                            <Link
                                to="photo"
                                smooth={true}
                                duration={500}
                                className="down-button-link"
                                aria-label="Scroll to photo section"
                                role="button"
                            >
                                <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                                    <path d="M12 16.5l-8-8h16z" />
                                </svg>
                            </Link>
                        </div>


                        <div className="up-button btn3U">
                            <Link
                                to="section2_1"
                                smooth={true}
                                duration={500}
                                className="up-button-link"
                                aria-label="Scroll to section 2_1"
                                role="button"
                            // offset={-60} // Отступ для учета фиксированного Navbar (например, 60px)
                            >
                                <svg className="arrow-up" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                                    <path d="M12 7.5l8 8H4z" />
                                </svg>
                            </Link>
                        </div>
                    </>


                )}


                {!showDownButton && (
                    <>
                        <div className="down-button btn4D">
                            <Link
                                to="section3"
                                smooth={true}
                                duration={500}
                                className="down-button-link"
                                aria-label="Scroll to photo section"
                                role="button"
                            >
                                <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                                    <path d="M12 16.5l-8-8h16z" />
                                </svg>
                            </Link>
                        </div>

                        <div className="up-button btn4U">
                            <Link
                                to="section2_2"
                                smooth={true}
                                duration={500}
                                className="up-button-link"
                                aria-label="Scroll to section 2_1"
                                role="button"
                            // offset={-60} // Отступ для учета фиксированного Navbar (например, 60px)
                            >
                                <svg className="arrow-up" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                                    <path d="M12 7.5l8 8H4z" />
                                </svg>
                            </Link>
                        </div>
                    </>


                )} */}

            </div>
        </section>
    );
};

export default Hero2_2;
