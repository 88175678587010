import React, { useState, useEffect } from 'react';
import '../styles/Hero3.css';
import { Link } from 'react-scroll';
import dochSmall from '../assets/dochSmall.png'
import dochBig from '../assets/dochBig.png'
import dochPair from '../assets/dochPair.png'



const Hero3_2 = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Определяем, мобильный ли экран


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Считаем экран мобильным, если ширина ≤ 768px
        };

        window.addEventListener('resize', handleResize); // Добавляем слушатель
        return () => window.removeEventListener('resize', handleResize); // Убираем слушатель
    }, []);


    return (
        <section className="hero3_2" id='section3_2'>
            <div className="hero3_2-content">

                <div>
                    {isMobile ? (
                        <> <h1>DOCH! Allrounder gibt's
                            klein oder groß</h1></>
                    ) : (
                        <> <h1>DOCH! Allrounder gibt's <br />
                            klein oder groß</h1></>
                    )}

                    <p>
                        Der DOCH! allrounder passt sich deinen Bedürfnissen an – egal ob du ihn erstmal probieren oder deine Küche dauerhaft aufwerten möchtest. Wähle zwischen der praktischen 50g-Packung für den täglichen Gebrauch oder der 500g-Größe für echte Würzliebhaber.
                    </p>
                    <p>
                        Schon bald gibt es den DOCH! allrounder auch in einer allergenfreien Variante – für noch mehr Genuss ohne Kompromisse.
                    </p>
                </div>


                <img className='dochSmall' src={dochPair} alt="DOCH!" aria-labelledby="dochPair-description" />
                <p id="dochPair-description" hidden>
                    Das Bild zeigt zwei Produktverpackungen des DOCH! allrounders vor einem weißen Hintergrund.
                    Links ist eine kleinere, wiederverschließbare Verpackung aus dunkelgrünem Material abgebildet.
                    Auf ihr steht in orangefarbenen Buchstaben „DOCH! flavours allrounder“, ergänzt durch den Claim
                    „Dein Revoluzzer mit natürlicher Würze“. Darunter ist eine kurze Beschreibung des Produkts aufgedruckt.
                    Rechts daneben steht eine größere Verpackung in Form einer Dose, ebenfalls dunkelgrün mit orangen Akzenten. 
                    Auch hier ist das Logo prominent zu sehen, zusammen mit der Produktbezeichnung „DOCH! flavours allrounder“.
                    Die Darstellung vermittelt klar die zwei Größenvarianten des Produkts: eine kompakte 50g-Verpackung und eine größere 500g-Dose.
                </p>

            </div>
            {/* <p className='dochSmall-p'>DOCH! allrounder 50g</p>
            <img className='dochSmall' src={dochSmall} alt="DOCH!" />
            <p className='dochBig-p'>DOCH! allrounder 500g</p>
            <img className='dochBig' src={dochBig} alt="DOCH!" /> */}



        </section>
    );
};

export default Hero3_2;
