import React from 'react';
import '../styles/Hero2.css';
import bg2 from '../assets/bg2.png'
import bgOLD from '../assets/bgOLD.jpg'
import { Link } from 'react-scroll';


const Hero2_1 = () => {
    return (
        <section className="hero2_1" id='section2_1' role="img" aria-labelledby="hero2_1-description">
            <p id="hero2_1-description" hidden>
                Dieses Bild zeigt eine Auswahl frischer Gemüsesorten, darunter Karotten, Lauch, Knoblauch, Pastinaken und Petersilie, 
                die in einem silbernen Drahtsieb angeordnet sind. Das Sieb schwebt vor einem schwarzen Hintergrund, 
                und darunter rieselt orangefarbenes Pulver – vermutlich ein Gewürz – heraus. Das Gemüse ist frisch und farbenfroh, 
                während das Pulver Dynamik und den Verarbeitungsprozess von frischen Zutaten zu einer Gewürzmischung symbolisiert.
            </p>
            {/* <div className='bg2'> */}
            {/* <div className='color'></div> */}
            <div className='text'>
                <h1>Das Marchfeld,</h1>
                <h3 className='p1_1'>Österreichs größter
                    <br />
                    Gemüsegarten
                </h3>
                <p>
                    Das Gemüse aus dem Marchfeld ist für seine Frische und Qualität bekannt – nicht nur in Österreich,
                    sondern international. Die regionalen Bauern setzen auf nachhaltigen Anbau und bewahren das
                    natürliche Gleichgewicht der Region. Diese Nähe zur Natur und das handwerkliche Geschick in der
                    Landwirtschaft machen das Marchfeld zur idealen Quelle für Produkte, die regional, frisch und
                    unverfälscht im Geschmack sind.
                </p>

                <p>
                    Eben auch wie unser „DOCH! allrounder“.
                </p>


            </div>

            {/* </div> */}

        </section>
    );
};

export default Hero2_1;
