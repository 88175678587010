// Hero1.js
import React from 'react';
import '../styles/Hero1.css';
import logo2 from '../assets/logo2.png';
import pack from '../assets/Verpackung.png';
import logoPack from '../assets/100Marchfeld RGB.svg';
import { Link } from 'react-scroll';

const Hero1 = () => {
  return (
    <>
      <section className="hero" id="hero1" role="img" aria-labelledby="hero-description">
        <p id="hero-description" hidden>
          Das Bild zeigt verschiedene Gemüsesorten, darunter Karotten, Pastinaken,
          Zwiebeln, Knoblauch, Lauch und Petersilie, die dynamisch dargestellt sind
          und durch eine goldene, pulverartige Wolke fliegen. Der Hintergrund ist
          dunkel, wodurch die Farben des Gemüses und des Pulvers besonders leuchten.
          Rechts unten auf dem Bild ist ein Text mit der Aufschrift „100% aus dem
          Marchfeld“ zu sehen, darunter ein stilisiertes Gemüse-Icon. Links unten ist
          die Verpackung des Produkts „DOCH! flavours Allrounder“ sichtbar. Im oberen
          Bereich des Bildes steht die Headline: „Wenn Gemüse fliegt, kommt DOCH!
          raus.“ Darunter folgt der Text: „Lieblingsspeisen würzen und geschmacklich
          abheben.“
        </p>
        <div className='heroTextWrapper'>
          <div>
            <p className="p1">Wenn Gemüse fliegt, <br></br> kommt <span style={{ color: '#f79553' }}><b>DOCH!</b></span> raus.</p>
            <p className="p2">Lieblingsspeisen <br></br>
              würzen und<br></br>
              geschmacklich abheben.</p>
          </div>

          <img className="logo2" src={logo2} alt="logo" />
          <img className="pack" src={pack} alt="pack" />
          <img className="logoPack" src={logoPack} alt="logoPack" />
        </div>
      </section>



      <div className="hero-buttons">
        <Link to="hero2" smooth={true} duration={500} className="btn">
          Warum DOCH!
        </Link>
        <Link to="hero2_2" smooth={true} duration={500} className="btn">
          Über uns

        </Link>

        {/* <a className="btn" href="#hero2_2">
          Unsere
          <br />
          <b>Geschichte</b>
          Über uns
        </a> */}

        {/* <a className="btn" href="#hero3_2">
          DOCH!
          <br />
          <b>jetzt kaufen</b>
          Jetzt probieren
        </a> */}

        <Link to="hero3_2" smooth={true} duration={500} className="btn">
          Jetzt probieren
        </Link>
      </div>
    </>
  );
};

export default Hero1;
